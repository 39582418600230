<template>
    <Teleport to="body">
        <ContactEmail
            id="employee-contact-email"
            :items="employee?.contact?.emails"
        />
        <ContactPhone
            id="employee-contact-phone"
            :items="employee?.contact?.phones"
        />
    </Teleport>

    <div class="row spacer-navigation spacer-navigation-overlap spacer-140-100">
        <div class="col-12 col-800-6 order-800-last">
            <div v-if="employee?.image" class="employee__image">
                <NuxtImg
                    sizes="sm:420px md:800px lg:830px"
                    densities="1x 2x"
                    format="webp"
                    :src="employee?.image?.src"
                    :alt="employee?.image?.alt"
                    loading="lazy"
                    :placeholder="[300, 200, 60, 2]"
                />
            </div>
        </div>
        <div class="col-12 col-800-6 align-self-end">
            <h2
                class="scale-6 employee__name"
                :id="anchorTitle"
                v-if="employee?.name"
            >
                {{ employee.name }}
            </h2>
            <h3
                class="scale-3 employee__function"
                :id="slugifyString(employee.function)"
            >
                {{ employee.function }}
            </h3>
            <ul class="d-flex flex-row employee__contact">
                <li v-if="employee?.contact?.emails">
                    <FormButton
                        class="l-button--secondary l-button--icon-only"
                        :size40="true"
                        data-bs-toggle="modal"
                        data-bs-target="#employee-contact-email"
                    >
                        <IconsMail />
                    </FormButton>
                </li>
                <li v-if="employee?.contact?.phones">
                    <FormButton
                        class="l-button--secondary l-button--icon-only"
                        :size40="true"
                        data-bs-toggle="modal"
                        data-bs-target="#employee-contact-phone"
                    >
                        <IconsPhone />
                    </FormButton>
                </li>
            </ul>
        </div>
    </div>
    <div class="row spacer-footer">
        <div class="col-12 col-800-6">
            <component
                v-if="information?.teaser?.image"
                :is="information?.teaser?.to ? NuxtLink : 'div'"
                :to="information?.teaser?.to"
                class="information__image"
            >
                <NuxtImg
                    sizes="sm:420px md:800px lg:830px"
                    densities="1x 2x"
                    format="webp"
                    :src="information?.teaser?.image?.src"
                    loading="lazy"
                    :alt="information?.teaser?.image?.alt"
                    :placeholder="[300, 200, 60, 2]"
                />
            </component>
        </div>
        <div class="col-12 col-800-6 align-self-center">
            <div
                v-if="information?.items.length > 0"
                class="d-flex flex-column information__content"
            >
                <template v-for="item in information?.items">
                    <div v-if="item?.type === 'quotation'" class="quotation">
                        <div
                            class="equal-base equal-base--tighter-bold-uppercase quotation__title"
                        >
                            {{ item.value.title }}
                        </div>
                        <Quotation :text="item.value.text" size="medium" />
                    </div>
                    <div v-if="item?.type === 'text'" class="text">
                        <div
                            class="equal-base equal-base--tighter-bold-uppercase text__title"
                        >
                            {{ item.value.title }}
                        </div>
                        <div
                            class="scale-1 scale-1--regular"
                            v-html="item.value.text"
                        ></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import { NuxtLink } from '#components';
import { computed } from 'vue';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

const employee = computed(() => page?.value?.data);
const information = computed(() => page?.value?.information);

const anchorTitle = computed(() => {
    return useAnchorTitle(page?.value?.data?.name);
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.employee {
    &__image {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: clamp(400px, 30.56vw + 302.22px, 80vh);

        img {
            position: relative;
            display: flex;
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            transition: 0.5s ease;
            object-position: top;
        }

        @include media-breakpoint-down(800) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(560) {
            margin: 0 $padding-container-negative 30px
                $padding-container-negative;
            width: auto;
        }
    }

    &__name {
        margin-top: auto;
    }

    &__function {
        margin-top: 5px;
        text-transform: none;
        font-weight: normal;
    }

    &__contact {
        list-style: none;
        padding: 0;
        margin: 20px 0 0 0;

        li {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}

.information {
    &__image {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: clamp(340px, 34.72vw + 228.89px, 840px);

        img {
            position: relative;
            display: flex;
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            transition: 0.5s ease;
        }

        @include media-breakpoint-down(800) {
            margin-bottom: 60px;
        }
    }

    &__content {
        & > div {
            &:not(:last-child) {
                margin-bottom: clamp(50px, 1.74vw + 44.44px, 75px);
            }
        }
    }
}

.quotation {
    &__title {
        margin-bottom: 10px;
    }
}

.text {
    &__title {
        margin-bottom: 15px;
    }

    p {
        margin: 0;
    }
}
</style>
